import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { User } from '../Models/User.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<firebase.User>;

  public currUser: firebase.User;
  public isLoggedIn = false;

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService) {
    this.user$ = afAuth.authState;
    afAuth.onAuthStateChanged(resp => {
      if (resp) {
        this.currUser = resp;
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  registerUser(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(
          res => resolve(res),
          err => reject(err));
    });
  }

  createOtherUser(user: User) {
    var config = {
      apiKey: 'AIzaSyAoVSo9zQGbQP_dAaDx8A6g_UYlR9N2WKE',
      authDomain: 'pharmappvet.firebaseapp.com',
      databaseURL: 'https://pharmappvet-default-rtdb.firebaseio.com'
    };
    var secondaryApp = firebase.initializeApp(config, "Secondary");
    secondaryApp.auth().createUserWithEmailAndPassword(user.email, user.password).then(resp => {
      user.uid = resp.user.uid;
      user.fcm = resp.user.refreshToken;
      console.log(user);
      this.userService.saveUserToDb(user)
      secondaryApp.auth().signOut();
    })


  }

  loginUser(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(
          res => resolve(res),
          err => reject(err));
    });
  }

  logoutUser() {
    return new Promise<any>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase.auth().signOut()
          .then(() => {
            res => resolve(res);
          }).catch((error) => {
            err => reject(err);
          });
      }
    });
  }

  userDetails() {
    return firebase.auth().currentUser;
  }

  registerUserFailed(user) {
    //TODO?
  }

}
