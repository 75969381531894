import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private navCtrl: NavController,
    private auth: AuthService
  ) { }

  canActivate(route, state: RouterStateSnapshot) {
    if (this.auth.user$) {
      return this.auth.user$.pipe(map(user => {
        if (user) {
          if (user.uid == 'gRSadV1lxSSNVSPPC72XNVhtmEI2' || user.uid == 'YwbPIHeNFLRatD1j44Wa3wm5CQN2') {
            return true;
          }
        }
        this.navCtrl.navigateRoot('/login');
        return false;
      }));
    }
    this.navCtrl.navigateRoot('/login');
    return false;
  }
}
