import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'medicines',
    loadChildren: () => import('./pages/medicines/medicines.module').then( m => m.MedicinesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addmedicine',
    loadChildren: () => import('./pages/addmedicine/addmedicine.module').then( m => m.AddmedicinePageModule)
  },
  {
    path: 'savemedicine',
    loadChildren: () => import('./pages/savemedicine/savemedicine.module').then( m => m.SavemedicinePageModule)
  },
  {
    path: 'editmedicine',
    loadChildren: () => import('./pages/editmedicine/editmedicine.module').then( m => m.EditmedicinePageModule)
  },
  {
    path: 'sections',
    loadChildren: () => import('./pages/sections/sections.module').then( m => m.SectionsPageModule)
  },
  {
    path: 'subsections',
    loadChildren: () => import('./pages/subsections/subsections.module').then( m => m.SubsectionsPageModule)
  },
  {
    path: 'addsubsection',
    loadChildren: () => import('./pages/addsubsection/addsubsection.module').then( m => m.AddsubsectionPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./pages/logs/logs.module').then( m => m.LogsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
