import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Dashboard', url: '/folder/Dashboard', icon: 'easel' },
    { title: 'Relatórios', url: '/folder/Relatórios', icon: 'bar-chart' },
    { title: 'Notificações', url: 'notifications', icon: 'mail' },
    { title: 'Usuários', url: 'users', icon: 'people' },
    { title: 'Cadastrar Usuário', url: 'register', icon: 'person-add' },
    { title: 'Medicamentos', url: 'medicines', icon: 'list' },
    { title: 'Incluir Medicamento', url: 'addmedicine', icon: 'medkit' },
    { title: 'Seções', url: 'subsections', icon: 'list' },
    { title: 'Adicionar Seção', url: 'addsubsection', icon: 'add' }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor() {}
}
