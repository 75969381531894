// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'pharmappvet',
    appId: '1:917312629343:web:1f76f2ca8eb335a43109ea',
    databaseURL: 'https://pharmappvet-default-rtdb.firebaseio.com',
    storageBucket: 'pharmappvet.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyAoVSo9zQGbQP_dAaDx8A6g_UYlR9N2WKE',
    authDomain: 'pharmappvet.firebaseapp.com',
    messagingSenderId: '917312629343',
    measurementId: 'G-9QFDNPHDVZ',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
