import { Injectable } from '@angular/core';
//import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { User } from '../Models/User.model';
import { Medicine } from '../Models/Medicine.model';
import { SubSection } from '../Models/SubSection.model';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userObj: any;
  constructor(
    //private storage: Storage,
    private navCtrl: NavController,
    private fb: AngularFireDatabase
  ) { }


  saveUserToDb(user: User) { // here we can use user model for more clarity
    return new Promise<any>((resolve, reject) => {
      this.fb.database.ref('/users/' + user.uid + '/').set(user)
        .then(
          res => resolve(res),
          err => reject(err));
    });
  }

  saveNewSubSectionToDb(subsection: string) {
    return new Promise<any>((resolve, reject) => {
      this.fb.database.ref('/subsections/').set(subsection)
        .then(
          res => resolve(res),
          err => reject(err));
    });
  }

  saveMedicineToDb(medicine: Medicine) {
    if (medicine.id !== undefined) {
      console.log('edit', medicine)
      return new Promise<any>((resolve, reject) => {
        this.fb.database.ref('/medicines/' + medicine.id + '/').set(medicine)
          .then(
            res => resolve(res),
            err => reject(err));
      });
    }
    else {
      const dbRef = this.fb.database.ref();
      return dbRef.child("indexes").child('medicinesLastId').get().then((snapshot) => {
        if (snapshot.exists()) {
          medicine.id = Number(snapshot.val()) + 1;
          return new Promise<any>((resolve, reject) => {
            this.fb.database.ref('/medicines/' + medicine.id + '/').set(medicine)
              .then(
                res => resolve(res),
                err => reject(err));
            return new Promise<any>((resolve, reject) => {
              this.fb.database.ref('/indexes/medicinesLastId').set(medicine.id)
                .then(
                  res => resolve(res),
                  err => reject(err));
            });
          });
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  }

  saveSubSectiontoDb(subSection: SubSection) {
    const dbRef = this.fb.database.ref();
    return dbRef.child("indexes").child('subsectionLastCount').get().then((snap) => {
      let count = Number(snap.val()) + 1;
      return dbRef.child("indexes").child('subsectionLastId').get().then((snapshot) => {
        if (snapshot.exists()) {
          subSection.id = Number(snapshot.val()) + 1;
          return new Promise<any>((resolve, reject) => {
            this.fb.database.ref('/subsections/' + count + '/').set(subSection)
              .then(
                res => resolve(res),
                err => reject(err));
            return new Promise<any>((resolve, reject) => {
              this.fb.database.ref('/indexes/subsectionLastId').set(subSection.id)
                .then(
                  res => resolve(res),
                  err => reject(err));
                  return new Promise<any>((resolve, reject) => {
                    this.fb.database.ref('/indexes/subsectionLastCount').set(count)
                      .then(
                        res => resolve(res),
                        err => reject(err));
                  });
            });
          });
        }
      }).catch((error) => {
        console.error(error);
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  getUser(uid) {
    /*
    return this.isUserExist().then(isExist => {
      if (!isExist) {

        return new Promise<any>((resolve, reject) => {
          this.fb.database.ref('/users/' + uid).once('value', snapshot => {
            if (snapshot.exists) {
              resolve(snapshot.val());
            } else {
              reject(snapshot);
            }
          });
        });

      } else {
        return new Promise<any>((resolve, reject) => {
          this.storage.get(uid).then(resp => {
            resolve(resp);
          });
        });
      }
    });
*/
  }


  getUserFromDb(uid: string) {
    return this.fb.object(`users/${uid}`).valueChanges();
  }

  getCRM(CRM: string): Observable<any> {
    return this.fb.list('users', ref => {
      return ref.orderByChild('CRM').equalTo(CRM);
    }).valueChanges();
  }

  isUserExist() {
    /*
    return this.storage.get(this.fb.database.app.auth().currentUser.uid).then(resp => {
      if (resp) {
        return true;
      } else {
        return false;
      }
    });
    */
  }

  logout() {
    /*
    this.storage.remove(this.fb.database.app.auth().currentUser.uid).then(resp => {
      this.fb.database.app.auth().signOut();
      this.navCtrl.navigateRoot('/');
    });
    */
  }
}
